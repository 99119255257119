
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        































.filter {
  background: tint-color($primary, 90%);
  padding: rem(4) rem(12);
  border-radius: rem(16);

  button {
    &:hover,
    &:focus {
      background: tint-color($primary, 80%);
    }
  }
}
