
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        




































































































































.wrapper {
  min-height: rem(29);
}

.popper-content {
  min-width: rem(270);
}
